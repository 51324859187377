// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // loanServiceUrl: 'https://gulapuckar-www-martin.nytthem.sparbankensyd.se/api',
  // commonServiceUrl: 'https://spb-common-api-martin.internal.sparbankensyd.se/service',
  loanServiceUrl: 'https://ll-martin.internal.sparbankensyd.se/api',
  commonServiceUrl: 'https://ll-martin.internal.sparbankensyd.se/service',
  userDoc: 'https://dokument-martin.lana.sparbankensyd.se/doc',
  showDebug: true,
  domain: 'll-test.internal.sparbankensyd.se'
}
