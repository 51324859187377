import {formatNumber} from '@angular/common'
import {
  IApplication,
  IApplicationOverride,
  OccupationType,
  TApplicationLogType,
  TApplicationStatus
} from '@sparbanken-syd/loan-backend'

export type TLoanPromiseStatusInput = Pick<IApplication, 'applicants' | 'status'
  | 'statusReason' | 'kalp' | 'overrides' | 'oldProperty'>

const completeStatuses: TApplicationStatus[] = ['approved', 'manually_issued']

const allowedOccupationStatuses: OccupationType[] = [OccupationType.PERMANENT, OccupationType.RETIRED]

const overrideToNoteMap: Record<keyof IApplicationOverride, TApplicationLogType> = {
  occupation: 'occupation',
  oldProperty: 'oldProperty',
  ucIncome: 'income'
}

const noteToOverrideMap: Record<TApplicationLogType, keyof IApplicationOverride | undefined> = {
  change: undefined,
  issue: undefined,
  note: undefined,
  occupation: 'occupation',
  oldProperty: 'oldProperty',
  income: 'ucIncome'
}

/**
 * Intends to let you know what is needed to continue or not.
 */
export class LoanPromiseStatus {
  /**
   * If this cannot complete, basically b/c of uc then
   * we mark it as denied. No further actions are possible
   * (except, perhaps, adding some notes.)
   */
  public permanentlyDenied: boolean

  /**
   * If complete, we consider this an application all done and issued.
   * That means, among other things, that there is a loan promise
   * to view (PDF)
   */
  public complete: boolean
  public completeManually: boolean

  /**
   * If this is not complete it might need some income verification
   * This is true if applicant occupation is ulfed or if UC income
   * is lower than what has been given.
   */
  public needsIncomeCheck: boolean = false

  /**
   * If the KALP is not up to snuff. No loans
   * can be issued if KALP is not positive.
   */
  public needsBetterKalp: boolean = false

  /**
   * Superfast is denied on certain property selections
   * in this case we need a manual check.
   */
  public needsPropertyCheck: boolean = false

  /**
   * Needs to have occupation type verified.
   */
  public needsOccupationCheck: boolean = false

  /**
   * An array that contains the applicants occupation status
   * true = The applicant has good occupation
   * false = The applicant has no good occupation
   */
  public applicantOccupations: boolean[] = []

  /**
   * This is a list of all overrides that are "true"
   * that is the value has been confirmed by an admin
   */
  public overrides: Array<keyof IApplicationOverride> = []

  /**
   * This is for legacy applications that were made w/o
   * BankID. This is basically if status is _pending_ that
   * we no longer get but exist in the old database.
   */
  public legacyManual: boolean = false

  /**
   * If this application can be issued
   */
  public canBeIssued: boolean = false

  public incomeDiffs: string[] = []

  constructor(application: TLoanPromiseStatusInput) {

    /**
     * These two assertions if for simplification
     * of test. Do not happen in real life.
     */
    application.applicants = application.applicants ?? []
    application.applicants[0] = application.applicants[0] || {}
    /**
     * First check if this can be completed at all.
     */
    this.permanentlyDenied = this.completeForbidden(application)

    /**
     * Kalp is "optional" in interface, so we assert it here.
     * If it needs better kalp it does
     */
    const kalp = application.kalp ?? 0
    this.needsBetterKalp = kalp < 1

    /**
     * In about six months we can remove this.
     */
    if (!application.applicants[0].uc) {
      this.legacyManual = true
    }

    /**
     * Then, check if it is complete. The check against
     * permanent denied should be superfluous
     */
    this.complete = !this.permanentlyDenied &&
      completeStatuses.indexOf(application.status) !== -1 &&
      // Remove this check in the future (approx. July 2024
      !this.legacyManual
    this.completeManually = this.complete && application.status === 'manually_issued'

    // OK, it is not complete, and it is not forbidden then what is needed?
    if (!this.complete && !this.permanentlyDenied) {
      this.needsIncomeCheck = this.checkIfIncomeIsNeeded(application)
      this.needsPropertyCheck = this.checkIfPropertyCheckIsNeeded(application)
      this.needsOccupationCheck = this.checkIfOccupationCheckIsNeeded(application)
    }

    if (application.overrides) {
      this.overrides = Object.entries(application.overrides)
        .filter(override => override[1])
        .map(override => override[0] as keyof IApplicationOverride)
    }

    this.canBeIssued = application.status === 'issuable'
  }

  /**
   * Translates the type of override to a note to be sent
   */
  public overrideToNote(override: keyof IApplicationOverride): TApplicationLogType {
    return overrideToNoteMap[override]
  }

  /**
   * Verifies if this is an override or not.
   * @param type
   */
  public hasOverride(type: TApplicationLogType): boolean {
    if (!noteToOverrideMap[type]) {
      return false
    } else {
      return this.overrides.indexOf(noteToOverrideMap[type] as keyof IApplicationOverride) !== -1
    }
  }

  /**
   * Under some circumstance we can never proceed. E.g. if UC says not.
   */
  private completeForbidden(application: TLoanPromiseStatusInput): boolean {
    return application.status === 'rejected' && application.statusReason === 'uc'
  }

  private checkIfIncomeIsNeeded(application: TLoanPromiseStatusInput): boolean {
    /**
     * If override is already done then this is done.
     * Or if no uc is done then this cannot be checked
     */
    if (application.overrides?.ucIncome === true || this.legacyManual) {
      return false
    }
    // This happens when the input income differs
    this.incomeDiffs = application.applicants
      .map(a => {
        if (a.uc?.incomeDiff !== 0) {
          const given = formatNumber(a.income as number * 12, 'fr', '1.0-0')
          const uc = formatNumber(Number.parseInt(a.uc!.income[0] + ''), 'fr', '1.0-0')
          return `Uppgivit ${given} vilket` +
            `är ${a.uc!.incomeDiff * 100}% mer än UC (${uc})`
        }
        return ''
      })
    return this.incomeDiffs.filter(d => d).length > 0
  }

  /**
   * Checks the old property this is a "superfast" property, if
   * Intend to sell, then this needs to be checked. Override is possible
   */
  private checkIfPropertyCheckIsNeeded(application: TLoanPromiseStatusInput): boolean {
    // No old property, that is fine.
    if (!application.oldProperty) {
      return false
    }
    /**
     * If old property and not overridden, then yes, must be checked
     * Complex if provided by intellisense.
     */
    return application.oldProperty.futurePropertyOwnership === 'intendToSell' &&
      application.overrides?.oldProperty !== true
  }

  private checkIfOccupationCheckIsNeeded(application: TLoanPromiseStatusInput): boolean {
    // If any is false you can check here if one or both.
    this.applicantOccupations = application.applicants
      .map(a => a.occupation)
      .map(o => allowedOccupationStatuses.indexOf(o) !== -1)
    // If not all is true none is true.
    return !this.applicantOccupations.every(s => s) && !application.overrides?.occupation
  }
}
