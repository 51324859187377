<div matDialogTitle>{{ data.title }}</div>
<mat-dialog-content>
  @if (data.text) {
    <div>{{ data.text }}</div>
  }
  @if (data.html) {
    <div [innerHtml]="htmlText"></div>
  }
  <mat-progress-bar></mat-progress-bar>
</mat-dialog-content>
@if (data.closable) {
  <mat-dialog-actions>
    <button matDialogClose="" mat-raised-button color="primary">Stäng</button>
  </mat-dialog-actions>
}